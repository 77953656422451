import React, { useEffect, useState } from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Login from "./components/login";
import SignUp from "./components/register";
import { Navbar } from "./components/navbar";
import Farwardnumber from "./components/Farwardnumber";
import Showsms from "./components/showsms";
import Datashow from "./components/Datashow";
import Number from "./components/number";
import Dashboard from "./components/dashboard";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Profile from "./components/profile";
import { auth } from "./components/firebase";

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <div className="App">
        <div className="auth-wrapper">
          <div className="auth-inner">
            <Routes>
              {/* Public Routes */}
              <Route path="/" element={user ? <Navigate to="/dashboard" /> : <Login />} />
              <Route path="/login" element={user ? <Navigate to="/dashboard" /> : <Login />} />
              <Route path="/registerujlopmnjuiobgh" element={user ? <Navigate to="/dashboard" /> : <SignUp />} />

              {/* Protected Routes */}
              <Route path="/profile" element={user ? <Profile /> : <Navigate to="/login" />} />
              <Route path="/navbar" element={user ? <Navbar /> : <Navigate to="/login" />} />
              <Route path="/showdata" element={user ? <Datashow /> : <Navigate to="/login" />} />
              <Route path="/callnumber" element={user ? <Farwardnumber /> : <Navigate to="/login" />} />
              <Route path="/showsms" element={user ? <Showsms /> : <Navigate to="/login" />} />
              <Route path="/number" element={user ? <Number /> : <Navigate to="/login" />} />
              <Route path="/dashboard" element={user ? <Dashboard /> : <Navigate to="/login" />} />
            </Routes>
            <ToastContainer />
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
